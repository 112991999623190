import React from 'react';
import '../css/jobtitle.css';
import restfulApiConfig from './config.js';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PotlatchLogo from '../img/PotlatchLogo.svg';
import { configure } from '@testing-library/react';


class JobTitle extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount(){
      document.title = "JobTitle Select";
      console.log(restfulApiConfig)
      axios.get(restfulApiConfig.api+"user",{withCredentials: true})
      .then((res)=>{
      if(res.data.jobTitle!==""){
      this.setState({value: res.data.jobTitle});
      // console.log(this.state.value)
      }
      }).catch((res)=>{
      console.log(res)
      });
      }

    jobtitle_data = [
        'Executive','Manager','Employee','Freelancer','Student'
    ]

    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit() {
      const job_title_data = {
        jobTitle: this.state.value,
      };
      // console.log(job_title_data);
      axios.patch(
        restfulApiConfig.api+"user",
        job_title_data,
        {withCredentials: true},
        {
          headers : {
          'Content-Type': 'application/json',
        }}
        ).then((res) => {
          // console.log(res)
  
      }).catch((err) => {
          console.log(err)
      })
    }
    
    render() {
      return (
        <div className="register_flow_wrapper">
          <div className="register_flow_logo"><img src={PotlatchLogo} alt="ロゴ"/></div>
          <div className="register_flow_box">
            <h2 className="objectives_jobtitle_h2">What's the closest description to your role?</h2>
            <div className="jobtitle_h4">This helps your Matches understand your perspective.</div>
            <div className="jobtitle_select">
              <select value={this.state.value} onChange={this.handleChange}>
              <option value="" className="jt_option"></option>
              { this.jobtitle_data.map( d => <option className="jt_option" value={d} key={d}>{d}</option>)}
              </select>
            </div >
            <div className="navigation_wrapper">
              <div
                className="register_back_button"
              >
                <Link to="/intro" className="register_back_link">
                  Back
                </Link>
              </div>
              <p>Step 3 of 3</p>
              <div
                className="register_button"
                onClick={this.handleSubmit}
              >
                <Link to="/" className="register_link">
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

export default JobTitle;