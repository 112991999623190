import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import "../css/header.css";
import restfulApiConfig from './config.js';
import PotlatchLogo from "../img/PotlatchLogo.svg";
// import MessageIcon from '../img/message_icon.png';
import ChatIcon from "../img/chat2.svg";

// const ul_style = {
//   display: "flex",
// };

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_info: [],
    };
  }

  componentDidMount() {
    axios
      .get(restfulApiConfig.api+"user", {
        withCredentials: true,
      })
      .then((res) => {
        this.setState({ user_info: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    return (
      <div id="navbar" class="flex flex-col sticky">
        <nav class="flex flex-row px-2 items-center justify-between">
          <div id="logo" class="px-4">
            <Link to="/">
              <img src={PotlatchLogo} class="h-16" alt="Potlatch Logo" />
            </Link>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <button className="text-blue-dark font-display font-bold p-4">
              <Link to="/user_profile">
                <div className="flex flex-row items-center">
                  <img
                    className="rounded-full w-10 h-10 bg-blue-dark"
                    src={this.state.user_info.imageUrl}
                    alt="Profile link"
                  />

                  <div class="w-4"></div>
                  <p>{this.state.user_info.nameFirst}</p>
                  <button className="text-blue-dark flex-grow font-display font-bold p-4">
                    <Link to="/chat">
                      <div className="flex flex-row items-center">
                        <img
                          src={ChatIcon}
                          className=" w-8 h-8"
                          alt="Chat link"
                        />
                      </div>
                    </Link>
                  </button>
                </div>
              </Link>
            </button>
          </div>
        </nav>
        <div id="divider" class="h-px bg-gray-2"></div>
      </div>
      // <div className="header">
      //     <div className="dashboard_link"><Link to='/' ><img src={PotlatchLogo} alt="Potlatch Logo"/></Link></div>
      //     <ul style={ul_style}>
      //         <li className="user_profile_link">
      //             <Link to='/user_profile' ><img src={this.state.user_info.imageUrl} alt="Profile link"/></Link>
      //         </li>
      //         <li className="user_profile_link">
      //             <Link to='/chat' ><img src={ChatIcon} alt="Chat link"/></Link>
      //         </li>
      //         {/* <li style={li_style}>
      //             <Link to='/logout' className="dashboard_link">Log out</Link>
      //         </li> */}
      //     </ul>
      // </div>
    );
  }
}

export default Header;
