import React from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select'
import axios from "axios";
import '../css/objectives.css';
import restfulApiConfig from './config.js';
import PotlatchLogo from '../img/PotlatchLogo.svg';

// import Bizdev from '../img/bizdev.png';
// import Brainstorm from '../img/brainstorm.png';
// import Expnewpro from '../img/exp_new_pro.png';
// import Expnewcomp from '../img/exploreothercom.png';
// import Findco from '../img/find_co.png';
// import Growyourteam from '../img/growyourteam.png';
// import Invest from '../img/invest.png';
// import Meetintrpeo from '../img/meet_intre_peo.png';
// import Mentother from '../img/ment_oth.png';
// import Orgnizeve from '../img/orgniz_eve.png';
// import Raifunds from '../img/rai_fund.png';
// import StartCompa from '../img/startcompany.png';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
//コミュニティテーブルから画像を引っ張ってきて配列に入れる

// const objectives_images = [
//   Bizdev,Brainstorm,Expnewpro,Expnewcomp,
//   Findco,Growyourteam,Invest,Meetintrpeo,
//   Mentother,Orgnizeve,Raifunds,StartCompa,
// ]

const objectives_options = [
  { value: 'Brainstorm with peers', label: 'Brainstorm with peers' },
  { value: 'Grow your team', label: 'Grow your team' },
  { value: 'Start a company', label: 'Start a company' },
  { value: 'Explore other company', label: 'Explore other company' },
  { value: 'Business development', label: 'Business development' },
  { value: 'Invest', label: 'Invest' },
  { value: 'Explore new projects', label: 'Explore new projects' },
  { value: 'Mentor others', label: 'Mentor others' },
  { value: 'Organize events', label: 'Organize events' },
  { value: 'Raise funding', label: 'Raise funding' },
  { value: 'Find a cofounder', label: 'Find a cofounder' },
  { value: 'Meet intresting people', label: 'Meet intresting people' },
]

const objectives = [];
const obj_data = {
  "objectives" : objectives
};
class Objectives extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value:[],
        user_data:[]
      };
  
      this.onChange = this.onChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    componentDidMount(){
      document.title = "Objective Select";
      
      axios.get(restfulApiConfig.api+"user",{withCredentials: true})
      .then((res)=>{
        this.setState({user_data: res.data})
      if(res.data.objectives!==""){
      let obj_options =[]
      obj_options = res.data.objectives.map((obj)=>{
        return {"value": obj, "label": obj}
      })
      this.setState({value: obj_options});
      }
      // console.log(this.state.value)
      }).catch((res)=>{
      console.log(res)
      });
      }

    onChange(value) {
      this.setState({value: value});
      // console.log(this.state.value)     
      for(let i = 0; i< value.length ; i++){
        objectives[i] = value[i].value;
      }
    }
  
    handleSubmit() {
      axios.patch(
        restfulApiConfig.api+"user", 
        obj_data, 
        {withCredentials: true},
      {
        headers : {
        'Content-Type': 'application/json',
      }}    
        ).then((res) => {
          // console.log(res)
          // console.log(obj_data)
      }).catch((err) => {
          console.log(err)
      }
      )
      // event.preventDefault();
    }
  
    render() {
      return (
        <div className="register_flow_wrapper">
          <div className="register_flow_logo"><img src={PotlatchLogo} alt="ロゴ"/></div>
          <div className="register_flow_box">
            <h2 className="objectives_jobtitle_h2">
              Welcome, {this.state.user_data.nameFirst} {this.state.user_data.nameLast} <br/>
              What topics are you interested in?
            </h2>
            <div className="objectives_h4">This helps us match you with people who share your interests.</div>
            <Select value={this.state.value} onChange={this.onChange} options={objectives_options} className="objectives_selections" isMulti  />
            <div className="navigation_wrapper">
              <p>Step 1 of 3</p>
              <div
                className="register_button"
                onClick={this.handleSubmit}
              >
                <Link to="/intro" className="register_link">
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

export default Objectives;