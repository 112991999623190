import React from "react";
import LinkedinButton from "../img/linkedInButton.png";
import restfulApiConfig from './config.js';
import PotlatchLogo from "../img/PotlatchLogo.svg";
import "../css/linkedInLogin.css";

class LinkedInLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  componentDidMount() {
    document.title = "Login";
  }

  UI() {
    return (
      <div className="flex flex-col justify-center items-center space-y-4 py-12 px-8">
        <div id="logo" class="px-4">
          <img src={PotlatchLogo} class="h-16" alt="Potlatch Logo" />
        </div>
        <div className="md:w-480px flex flex-col space-y-8 border-2 border-blue-dark rounded-2xl py-6 px-8 md:px-12 md:py-8">
          <h2 className="font-display font-bold text-2xl text-center text-blue-dark">
            Create deeper connections with people in your community.
          </h2>
          <button className="py-4 px-8 rounded-2xl bg-linkedin-blue">
            <a
              className="font-bold text-base text-white font-display"
              href={restfulApiConfig.api+"linkedin-login"}
            >
              Continue with LinkedIn
            </a>
          </button>
        </div>
        <div className="md:w-480px text-center font-body text-gray-3">
          By continuing, you agree to Potlatch’s{" "}
          <span className="underline">
            <a href="https://www.trypotlatch.com/privacy-policy">
              privacy policy
            </a>
          </span>{" "}
          and{" "}
          <span className="underline">
            <a href="https://www.trypotlatch.com/terms-of-conditions">
              terms of service
            </a>
          </span>
          .
        </div>
      </div>
    );
  }

  oldUI() {
    return (
      <>
        <h2 className="likeninlogin_h2">Please authenticate LinkedIn.</h2>
        <div className="likeninlogin_box">
          {/* <a className="likeninlogin_button" href="https://v1api-dev.potlatch.io/linkedin-login"> */}
          <a
            className="likeninlogin_button"
            href="https://v1api.potlatch.io/linkedin-login"
          >
            <img src={LinkedinButton} alt="Click here" />
          </a>
        </div>
      </>
    );
  }

  render() {
    return this.UI();
  }
}

export default LinkedInLogin;
